<template>
	<Container title="系统数据字典" :returnBtn="false" :addBtn="adddict" :hideBtn="hideBtn">
		<div style="width: 100%; display: flex; height: 100%">
			<div style="
          display: flex;
          width: 17%;
          height: 100%;
          padding: 1px 10px;
          border-right: 1px solid #dfdfdf;
          overflow-y: auto;
          flex-direction: column;
          overflow-x: hidden;
        ">
				<div style="margin-right: 10px">
					<div :key="k" v-for="(item, k) in dicts" class="itemdict" :style="{
              background: item.id == currentDict.id ? '#989fff22' : 'none',
            }" @click="csthisitem(item)">
						<div style="display: flex;align-items: center;">
							<img src="/static/dicticon.png" style="width: 18px; height: 18px" />
							<div style="margin-left: 5px; font-size: 14px">{{ item.name }}</div>
							<div style="margin-left: 5px; font-size: 14px"><el-tag size="small">{{item.id}}</el-tag></div>
						</div>
						<div>
							<el-icon v-if="showdelete" @click="deldict(item)">
								<Delete />
							</el-icon>
						</div>
					</div>
				</div>
			</div>
			<div style="
          display: flex;
          width: 83%;
          height: 100%;
          padding: 15px;
          background: #fff;
          margin-left: 10px;
        ">
				<div style="width: 100%">
					<DictTableView />
				</div>
			</div>
		</div>

		<el-dialog v-model="dialogFormVisible" title="添加数据字典" width="500">
			<el-form :rules="rules" :model="form" ref="formDict">
				<el-form-item label="字典索引" label-width="auto" label-position="top" required prop="dbname">
					<el-input placeholder="填写字典索引" v-model="form.dbname" autocomplete="off" />
				</el-form-item>
				<el-form-item label="字典名称" label-width="auto" label-position="top" required prop="name">
					<el-input placeholder="填写字典名称" v-model="form.name" autocomplete="off" />
				</el-form-item>
				<el-form-item label="扩展字段" label-width="auto" label-position="top">
					<el-select v-model="form.ishavenote" placeholder="Please select a zone">
						<el-option label="是" :value="1" />
						<el-option label="否" :value="0" />
					</el-select>
				</el-form-item>
				<el-form-item label="图像字段" label-width="auto" label-position="top">
					<el-select v-model="form.ishavepicture" placeholder="Please select a zone">
						<el-option label="是" :value="1" />
						<el-option label="否" :value="0" />
					</el-select>
				</el-form-item>
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="canceladddict">取消</el-button>
					<el-button @click="confirmadddict" type="primary"> 确定 </el-button>
				</div>
			</template>
		</el-dialog>
	</Container>
</template>

<script>
	import moment from "moment";
	import {
		pub
	} from "../../../common/event";
	import {
		mapState
	} from "vuex";
	export default {
		data: () => {
			return {
				form: {
					ishavenote: 0,
					ishavepicture: 0,
					dbname: "",
				},
				rules: {},
				hideBtn: false,
				showdelete: false,
				dialogFormVisible: false,
				key0: "",
			};
		},
		computed: {
			...mapState({
				dicts: (state) => {
					return state.dict.dicts;
				},
				currentDict: (state) => {
					return state.dict.current;
				},
			}),
		},
		mounted: function() {
			this.init();
			window.addEventListener("keypress", (e) => {
				this.key0 = this.key0 + e.key;
				if (this.key0.includes("bgyxadddict")) {
					this.hideBtn = true;
				}
				if (this.key0.includes("bgyxdeletedict")) {
					this.showdelete = true;
				}
			});
		},
		methods: {
			deldict: async function(item) {
				const ret = await this.$api.dict.del(item.id)
				this.$store.dispatch({
				    type:"dict/getDicts",
				    payload:{}
				})
				this.$store.commit(`dict/setCurrent`,{})
			},
			isAllEnglish: function(r, value, callback) {
				if (!value) {
					callback(new Error("必填项"));
				} else if (!/^[A-Za-z]+$/.test(value)) {
					callback(new Error("必须全部英文"));
				} else {
					callback();
				}
			},
			csthisitem: async function(item) {
				this.$store.commit(`dict/setCurrent`, item);
			},
			adddict: async function() {
				this.dialogFormVisible = true;
			},
			init: async function() {
				this.rules = {
					dbname: [{
						validator: this.isAllEnglish,
						trigger: "blur",
					}, ],
				};
			},
			canceladddict: async function() {
				this.dialogFormVisible = false;
				this.form = {
					ishavenote: 0,
					ishavepicture: 0,
				};
			},
			confirmadddict: async function() {
				this.$refs["formDict"].validate(async (valid) => {
					if (valid) {
						this.dialogFormVisible = false;
						console.log(this.form);
						const ret = await this.$api.dict.post(this.form);
						if (ret.success) {
							pub("success_message", "添加成功");
							this.$store.dispatch({
								type: "dict/getDicts",
								payload: {},
							});
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
	};
</script>

<style scoped>
	.itemdict {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 100%;
		margin-bottom: 5px;
		cursor: pointer;
		border-radius: 5px;
		justify-content: space-between;
	}
</style>