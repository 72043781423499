import request from '../../../common/request/index.js'
const api = request.api

export default {
	get:async (id)=>{
		return api.get(`account/account/${id}`)
	},
	search:async (data)=>{
		return api.post(`account/account/search`,data)
	},
	page:async (data)=>{
		return api.post(`account/account/page`,data)
	},
	put:async (data)=>{
		return api.put(`account/account`,data)
	},
	post:async (data)=>{
		return api.post(`account/account`,data)
	},
	del:async (id)=>{
		return api.delete(`account/account/${id}`)
	},
}