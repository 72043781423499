<template>
	<Container title="系统模块列表" :returnBtn="false" :addBtn="addData" :onConfirm="false">
		<BgyxDbTableView />
	</Container>
</template>

<script>
	export default {
		data: () => {
			return {
				list:[]
			}
		},
		mounted: function() {
			
			this.init()
		},
		methods:{
			init: async function() {
				
			}
		}
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>