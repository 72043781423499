<template>
	<Container title="数据库列表" :returnBtn="false" :addBtn="false" :onConfirm="false">
		<DbsTableView />
	</Container>
</template>

<script>
import moment from 'moment'
import { pub } from '/common/event'
	export default {
		data: () => {
			return {
				form: {},
				rules: {},
			}
		},
		mounted: function() {
			
		},
		methods:{
			
		}
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>