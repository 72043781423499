import { createStore } from "vuex";
import { inits } from './storeInits.js'

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {

  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  if (value.default.init) {
    inits.push(value.default.init)
  }
  return modules
}, {})

const store = createStore({
  modules
})

export default store