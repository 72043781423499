<template>
	<div style="
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
      ">

		<div style="flex: 1; flex-direction: column; overflow-y: hidden">
			<div style="
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            background: blue;
            height: 92%;
          ">
				<el-table @row-click="clickrow" :data="tableData" height="100%" style="width: 100%" :border="true"
					stripe :header-cell-style="stt">
					<el-table-column prop="account" label="账号" />
					<el-table-column prop="name" label="名称">
						<template #default="{ row }">
							{{ row.name }}
						</template>
					</el-table-column>
					<el-table-column prop="status" label="状态">
						<template #default="{ row }">
							<el-tag v-if="row.status == 1" type="success">正常</el-tag>
							<el-tag v-if="row.status == 0" type="danger">禁用</el-tag>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="
            width: 100%;
            display: flex;
            height: 8%;
            justify-content: flex-end;
            align-items: center;
          ">
				<div style="margin-right: 10px">
					<el-pagination @change="pageChange" v-model:current-page="page.current" :page-size="page.pageSize"
						:total="page.total" background layout="prev, pager, next" />
				</div>
			</div>
		</div>
		<div v-if="current.id"
			style="height: 100%;width: 35%;padding: 15px;border-left: 1px solid #e5e5e5;margin-left: 15px;display: flex;flex-direction: column;">
			<div style="margin-bottom: 5px;display: flex;align-items: center;border-bottom: 1px solid #e1e1e1;padding-bottom: 15px;width: 100%;justify-content: space-between;">
				<div style="display: flex;align-items: center;">
					<el-avatar size="small" :src="$store.state.common.systemInfo.fileurl + current.avatar"></el-avatar>
					<div style="margin-left: 5px;font-size: 14px;font-weight: bold;">{{current.name}}</div>
				</div>
				<div>
					<el-button @click="saveauth" type="primary" size="default" round>保存权限</el-button>
				</div>
			</div>
			<div style="flex: 1;overflow-y: auto;padding: 10px;width: 100%;">
				<el-checkbox-group v-model="authlist" style="display: flex;flex-direction: column;">
					<el-checkbox v-for="(item,k) in menus" :key="`elckek_${k}`" :label="item.name" :value="item.name" style="margin: 3px;" />
				</el-checkbox-group>
				<div style="margin-top: 30px;"></div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		pub
	} from '../../../common/event';
	import {
		mapState
	} from "vuex";
	export default {
		data: () => {
			return {
				current: {},
				form: {},
				tableData: [],
				authlist: [],
				page: {
					current: 1,
					pageSize: 15,
					total: 15,
				},
				dialogFormVisible: false,
				rules: {},
				stt: {
					"background": "#209cd111",
					"color": "#209cd1"
				}
			};
		},
		props: {},
		mounted() {
			this.getData();
		},
		computed: {
			...mapState({
				menus: (state) => {
					return state.menu.list[0].menus.concat(state.menu.list[1].menus);
				},
			}),
		},
		methods: {
			filterWhere: function() {
				const whereform = this.form;
				for (let k in whereform) {
					if (!whereform[k]) {
						delete whereform[k];
					}
				}
				const where = {
					current: this.page.current,
					pageSize: this.page.pageSize,
					...whereform,
				};

				return where;
			},
			clickrow: async function(e) {
				console.log(e)
				this.current = e
				const ret = await this.$r.get(`account/account/account/auth/${e.id}`)
				if(ret.success){
					this.authlist = ret.data ?? []
				}else{
					this.authlist = []
				}
			},
			getData: async function() {
				const where = this.filterWhere();
				const ret = await this.$api.account.page(where);
				console.log(ret);
				if (ret && ret.success) {
					this.tableData = ret.data.data.filter(e => e.id > 1);
					this.page.total = ret.data.total;
					this.page.current = ret.data.page.current;
					this.page.pageSize = ret.data.page.pageSize;
				}

			},
			pageChange: function(e, v) {
				console.log(e, v);
				this.page.current = e;
				this.page.pageSize = v;
				this.getData();
			},
			saveauth:async function(){
				const ret = await this.$r.put(`account/account/accountandauth`,{id:this.current.id,data:this.authlist})
				if(ret.success){
					pub("success_message","保存成功")
					this.$store.dispatch({
						type:`user/getUserAuth`,
						payload:{}
					})
				}
			}
		},
	};
</script>

<style></style>