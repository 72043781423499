<template>
	<div id="viewDiv" style="width: 100%;height: 100%;"></div>
</template>

<script>
	export default {
		mounted() {
			setTimeout(() => {
				this.initMap();
			}, 500)
		},
		props: {
			
			form: {
				type: Object
			},
			name: String
		},
		methods: {
			async initMap() {
				const _this = this
				// 确保在调用ArcGIS API方法之前，ArcGIS API已经加载。
				const arcgis = await import("@arcgis/core/Map");
				const arcgisv = await import("@arcgis/core/views/MapView");
				const {
					Basemap
				} = await import("@arcgis/core/Basemap");
				const Map = arcgis.default
				const MapView = arcgisv.default
				const map = new Map({
					basemap: "streets",
					logo: false
				});
				
				const latlng = this.form[this.name]
				
				let lat = 45.67162614431423
				let lng = 126.53656105320522
				
				if(latlng){
					const latlngarr = latlng.split(',')
					lat = latlngarr[0]
					lng = latlngarr[1]
				}
				
				const view = new MapView({
					container: "viewDiv", // 对应于HTML中的div的id
					map: map,
					center: [lng, lat], // Longitude, latitude
					zoom: 13, // 缩放级别
					attribution: {
						// 将attribution设置为空字符串
						container: "none"
					}
				});

				// 当视图完成加载时，你可以在这里添加更多的代码
				view.when(() => {
					console.log("Map view is ready.");
					console.log(9999,view)
					view.on("click",(e)=>{
						console.log(99999,map)
						const latn = e.mapPoint.latitude
						const lngn = e.mapPoint.longitude
						_this.form[_this.name] = latn + "," + lngn
						map.addPoint({
							...e.mapPoint
						})
					})
				});
				
				
			}
		}
	};
</script>

<style>
	.esri-ui-manual-container>.esri-component {
		display: none;
	}

	.esri-scale-bar .esri-scale-bar--inner {
		display: none;
	}
</style>