<template>
	<div style="text-align: left;height: 300px;width: 100%;margin-bottom:30px;">
		<Toolbar style="border: 1px solid #8091cc33" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
		<Editor
			style="min-height: 300px; overflow-y: hidden;border: 1px solid #8091cc33;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;"
			v-model="form[name]" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
	</div>
</template>

<script>
	import '@wangeditor/editor/dist/css/style.css'
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		getCurrentInstance
	} from 'vue'
	import {
		useStore
	} from 'vuex'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'

	export default {
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				valueHtml: '',
			}
		},
		props: {
			value: {
				type: String,
				default: '<p></p>'
			},
			form: {
				type: Object
			},
			name: String
		},
		watch: {
			// valueHtml: function(v) {
			// 	console.log(v)
			// 	this.form[this.name] = v
			// }
		},
		setup(props) {

			const {
				proxy
			} = getCurrentInstance();
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('<p></p>')

			const editorConfig = {
				MENU_CONF: {},
				placeholder: '请输入内容...'
			}

			editorConfig.MENU_CONF['uploadImage'] = {
				server: `${proxy.$store.state.config.host}:${proxy.$store.state.config.port}/upload/upload/htmlfile`,
				fieldName: 'file'
			}

			// 模拟 ajax 异步获取内容
			onMounted(() => {
				setTimeout(() => {
					if (props.value) {
						valueHtml.value = props.value ?? "<p></p>"
					}
				}, 200)
			})

			const toolbarConfig = {}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
			}

			return {
				editorRef,
				valueHtml,
				mode: 'simple', // 或 'simple'
				toolbarConfig,
				editorConfig,
				handleCreated
			};
		}
	}
</script>