<template>
	<Container title="系统参数设置" :returnBtn="false" :addBtn="false" :onConfirm="onConfirm">
		<div style="width: 100%;display: flex;padding-bottom: 50px;">
			
			<el-form :rules="rules" :model="form" label-width="auto" label-position="top" ref="formName"
				style="display: flex;width: 100%;flex-wrap: wrap;">
				<el-form-item label="系统名称" style="width: 24%;margin: 0.5%;" prop="name">
					<el-input v-model="form.name" />
				</el-form-item>
				<el-form-item required label="客户端名称" style="width: 24%;margin: 0.5%;" prop="appname">
					<el-input v-model="form.appname" />
				</el-form-item>
				<el-form-item label="Token有效期(hours)" style="width: 24%;margin: 0.5%;">
					<el-input type="number" v-model="form.token_time" />
				</el-form-item>
				<el-form-item label="LongToken有效期(hours)" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.longtoken_time" />
				</el-form-item>
				<el-form-item label="服务接口地址" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.serverurl" />
				</el-form-item>
				<el-form-item label="服务接口端口" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.port" />
				</el-form-item>
				<el-form-item label="文件服务地址" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.fileurl" />
				</el-form-item>
				<el-form-item required label="文件服务类型" style="width: 24%;margin: 0.5%;">
					<el-select v-model="form.filesavetype" placeholder="文件服务类型">
						<el-option label="Server" value="server" />
						<el-option label="Minio" value="minio" />
					</el-select>
				</el-form-item>
				<el-form-item label="微信appid" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.wxappid" />
				</el-form-item>
				<el-form-item label="微信Secret" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.wxsecret" />
				</el-form-item>
				<el-form-item label="微信商户号" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.mchid" />
				</el-form-item>
				<el-form-item label="微信支付密钥" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.paysecret" />
				</el-form-item>
				
				<el-form-item label="Logo" style="width: 100%;margin: 0.5%;">
					<el-upload
					  class="avatar-uploader"
					  :action="`${$store.state.config.host}:${$store.state.config.port}/upload/upload/image`"
					  :show-file-list="false"
					  :on-success="(e)=>{
						  this.form.logo = e.data
					  }" >
					  <img v-if="form.logo" :src="$store.state.common.systemInfo.fileurl + form.logo" class="avatar">
					  <i v-else class="el-icon-plus avatar-uploader-icon">
						  <el-icon><UploadFilled /></el-icon>
					  </i>
					</el-upload>
				</el-form-item>

				<el-form-item label="平台说明" style="width: 100%;margin: 0.5%;">
					<FormEditItem :value="form.content" :form="form" name="content" />
				</el-form-item>

			</el-form>
		</div>
	</Container>
</template>

<script>
import moment from 'moment'
import { pub } from '../../../common/event'
	export default {
		data: () => {
			return {
				form: {},
				rules: {},
			}
		},
		mounted: function() {
			this.init()
		},
		methods: {
			onConfirm: async function() {
				console.log(this.form)
				this.$refs['formName'].validate(async (valid) => {
					if (valid) {
						const ret = await this.$api.system.putSystemInfo(this.form)
						if(ret){
							pub("success_message","编辑成功")
							pub("sysinfochange",this.form)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			init: async function() {
			   const ret = await this.$api.system.getSystemInfo()
			   const retdb = await this.$r.post(`common/common/getdata/dbfieldsinfo`,{where:{dbid:2}})
			   console.log(retdb.data)
			   const fielter = retdb.data.map(e=>e.name)
			   const fdata = {}
			   for(let k in ret.data){
				if(fielter.includes(k)){
					fdata[k] = ret.data[k]
				}
			   }
			   this.form = fdata
			},
			beforeAvatarUpload:function(){},
			handleAvatarSuccess:function(e){
				this.form.logo = e.data
			},
		},
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>