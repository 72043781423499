import request from '../../../common/request/index.js'
const api = request.api

export default {
	getSystemInfo:async ()=>{
		return api.get(`common/common/getSystemInfo`)
	},
	putSystemInfo:async (data)=>{
		return api.put(`common/common/updateSystemInfo`,data)
	},
}