const inits = []

const initAllStore = function(payload){
	for(let f of inits){
		f(payload)
	}
}

module.exports = {
	inits,
	initAllStore
}