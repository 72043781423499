import { sub } from '../../common/event.js'
import config from '../../common/config.js'
import store from '../index.js'

export default {
	namespaced: true,
	state: {
		host: config.host,
		port: config.port,
		language: 'ch',
		origin: {
			leftWidth: '15%',
		},
		menu: {
			leftWidth: '15%',
			topHeight: '60px',
			leftCollapse: false
		},
		leftbar:true,
		topbar:true,
	},
	mutations: {
		setLanguage: (state, payload) => {
			state.language = payload
		},
		setMenuData: (state, payload) => {
			state.menu[payload.key] = payload.data
		},
		setLeftBar: (state, payload) => {
			state.leftbar = payload
		},
		setTopBar: (state, payload) => {
			state.topbar = payload
		},
	},
	actions: {
		// async init({
		// 	commit,
		// 	state
		// }, {
		// 	payload
		// }) {


		// }
	},
	init: async function() {
		console.log("config初始化")
		sub("loginSuccess",()=>{
			store.commit(`config/setLeftBar`,true)
			store.commit(`config/setTopBar`,true)
		})
	}
}