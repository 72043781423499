let ws;

const config = require("./config")

const init = (id) => {
  ws = new WebSocket(config.default.socketurl + "/" + id);

  ws.onopen = () => {
    console.log("WebSocket connected");
  };

  ws.onmessage = (msg) => {
    this.message = msg.data;
  };

  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  ws.onclose = () => {
    console.log("WebSocket disconnected");
  };
};

module.exports = {
    init
}