


export default {
	install:function(app){
		const modulesFiles = require.context('./api', true, /\.js$/)
		
		const modules = modulesFiles.keys().reduce((modules, modulePath) => {
		  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
		  const value = modulesFiles(modulePath)
		  modules[moduleName] = value.default
		  return modules
		}, {})
		
		console.log("modules",modules)
		app.config.globalProperties.$api = modules
	}
}