<template>
    <div style="
                        	      width: 100%;
                        	      height: 100%;
                        	      display: flex;
                        	      align-items: center;
                        	      flex-direction: column;
                        	      overflow: hidden;
                        	    ">
        <div style="
                        	        width: 100%;
                        	        display: flex;
                        	        justify-content: space-between;
                        	        align-items: flex-end;
                        	        padding: 1px;
                        	      ">
            <div style="
                        	          width: 100%;
                        	          padding: 10px;
                        	          display: flex;
                        	          flex-wrap: wrap;
                        	          margin-bottom: 2px;
                        	          flex: 1;
                        	        ">
                <el-form :model="form" label-width="auto" label-position="top" ref="formName"
                    style="display: flex; width: 100%; flex-wrap: wrap">
                    <el-form-item label="名称" style="width: 24%; margin: 0.5%" prop="dbname">
                        <el-input clearable v-model="form.dbname" placeholder="搜索名称" />
                    </el-form-item>
                </el-form>
            </div>
            <div style="
                        	          padding: 20px;
                        	          display: flex;
                        	          align-items: center;
                        	          margin-top: 25px;
                        	        ">
                <div>
                    <el-button @click="reset">重置</el-button>
                </div>
                <div style="margin-left: 5px">
                    <el-button @click="search" type="primary">查询</el-button>
                </div>
            </div>
        </div>
        <div style="width: 100%; flex: 1; flex-direction: column; overflow-y: hidden">
            <div style="
                        	          width: 100%;
                        	          display: flex;
                        	          flex-direction: column;
                        	          overflow-y: auto;
                        	          background: blue;
                        	          height: 92%;
                        	        ">
                <el-table :data="tableData" height="100%" style="width: 100%" :border="true" stripe
                    :header-cell-style="stt">
                    <el-table-column prop="dbname" label="模块索引">
                        <template #default="{ row }">
                            {{ row["dbname"].replace(`bgyx_`, '') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="dbtitle" label="模块名称">
                        <template #default="{ row }">
                            {{ row["dbtitle"] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="icon" label="模块图标">
                        <template #default="{ row }">
                            <el-icon style="font-size: 16px;">
                                <component :is="$store.state.icons.icons[row.icon]"></component>
                            </el-icon>

                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="模块状态">
                        <template #default="{ row }">
                            <div v-if="row['status'] == 2">
                                <el-icon color="green">
                                    <CircleCheck />
                                </el-icon>
                            </div>
                            <div v-if="row['status'] == 9">
                                <el-icon color="red">
                                    <CircleClose />
                                </el-icon>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="160">
                        <template #default="{ row }">
                            <el-button type="success" size="small" plain @click.stop="addfields(row)">
                                添加字段
                            </el-button>
                            <el-button v-if="row.status == 9" type="primary" size="small" plain @click.stop="edit(row)">
                                恢复
                            </el-button>
                            <el-popconfirm v-if="row.status == 2" confirm-button-text="隐藏" cancel-button-text="取消"
                                title="确定隐藏吗?" @confirm="del(row)">
                                <template #reference>
                                    <el-button type="danger" size="small" plain>隐藏</el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="
                        	          width: 100%;
                        	          display: flex;
                        	          height: 8%;
                        	          justify-content: flex-end;
                        	          align-items: center;
                        	        ">
                <div style="margin-right: 10px">
                    <el-pagination @change="pageChange" v-model:current-page="page.current" :page-size="page.pageSize"
                        :total="page.total" background layout="prev, pager, next" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { tSMethodSignature } from '@babel/types';
import {
    pub
} from '../../../common/event';
import moment from 'moment'
export default {
    data: () => {
        return {
            form: {},
            tableData: [],
            dbinfo: {},
            fields: [],
            page: {
                current: 1,
                pageSize: 15,
                total: 15,
            },
            dialogFormVisible: false,
            rules: {},
            stt: {
                "background": "#209cd111",
                "color": "#209cd1"
            }
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        filterWhere: function () {
            const whereform = JSON.parse(JSON.stringify(this.form));
            for (let k in whereform) {
                if (!whereform[k]) {
                    delete whereform[k];
                }
            }
            if (whereform["name"]) {
                whereform["name"] = {
                    "like": '%' + whereform['name'] + '%'
                }
            }
            const where = {
                current: this.page.current,
                pageSize: this.page.pageSize,
                ...whereform,
            };

            return where;
        },
        getData: async function () {
            // alert(this.dbname)
            const where = this.filterWhere();
            where.status = {
                ">": 1
            }
            const ret = await this.$api.bgyx.page("dbinfo", where);
            console.log("9966887", ret);
            if (ret && ret.success) {
                this.tableData = ret.data.data;
                this.page.total = ret.data.total;
                this.page.current = ret.data.page.current;
                this.page.pageSize = ret.data.page.pageSize;
            }
        },
        addfields: function (e) {
            this.$router.push(`/system/bgyx/edit?dbname=${e.dbname}&id=${e.id}`)
        },
        edit: async function (e) {
            console.log(e);
            const ret = await this.$api.bgyx.restatusmodel(e.id);
            pub("success_message", "删除成功")
            this.getData();
            this.$store.dispatch({
                type: "database/getList",
                payload: {}
            })
        },
        del: async function (e) {
            console.log(e);
            const ret = await this.$api.bgyx.delmodel(e.id);
            pub("success_message", "删除成功")
            this.getData();
            this.$store.dispatch({
                type: "database/getList",
                payload: {}
            })
        },
        pageChange: function (e, v) {
            console.log(e, v);
            this.page.current = e;
            this.page.pageSize = v;
            this.getData();
        },
        reset: function () {
            this.form = {};
            this.$refs["formName"].resetFields();
            this.getData();
        },
        search: function () {
            this.getData();
        },
    },
};
</script>

<style></style>