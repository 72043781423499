
export default {
	namespaced: true,
	state: {
		current: 0,
		list: [{
			name: "系统设置",
			icon: "SetUp",
			menus: [{
				name: "系统参数设置",
				index: 0,
				path: "/system/index",
				icon: "Monitor"
			}, {
				name: "系统管理员",
				index: 1,
				icon: "User",
				menus: [{
					name: "管理员列表",
					path: "/system/account/list",
				}, {
					name: "添加管理员",
					path: "/system/account/add",
				}]
			},{
				name: "管理员权限",
				index: 2,
				icon: "Umbrella",
				path: "/system/account/auth",
			}, {
				name: "数据字典",
				index: 3,
				icon: "Cpu",
				path: "/system/dict",
			}, {
				name: "数据库管理",
				index: 4,
				icon: "Memo",
				path: "/system/dbs",
			}, {
				name: "系统模块管理",
				index: 5,
				icon: "DataLine",
				menus: [{
					name: "系统模块列表",
					path: "/system/bgyx/list"
				},{
					name: "部署系统模块",
					path: "/system/bgyx/add"
				}]
			}]
		}, {
			name: "运营管理",
			icon: "CoffeeCup",
			menus: []
		}]
	},
	mutations: {
		setListData: (state, payload) => {
			state.list = payload
		},
		setCurrentData: (state, payload) => {
			state.current = payload
		},
		setBgyxMenus: (state, payload) => {
			state.list[1].menus = payload
		},
	},
	actions: {
		// async init({
		// 	commit,
		// 	state
		// }, {
		// 	payload
		// }) {


		// }
	},
	init: async function() {
		console.log("config初始化")

		
	}
}