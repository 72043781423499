<template>
  <div>
    HOMEddd-{{name}}
  </div>
</template>

<script>
export default {
    data:()=>{
        return {
            name:"homedd"
        }
    }
}
</script>