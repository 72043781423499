import request from '../../../common/request/index.js'
const api = request.api

export default {
	get:async (dbname,id)=>{
		return api.get(`common/common/${dbname}/${id}`)
	},
	getdata:async (dbname,data)=>{
		return api.post(`common/common/getdata/${dbname}`,data)
	},
	page:async (dbname,data)=>{
		return api.post(`common/common/page/${dbname}`,data)
	},
	put:async (dbname,data)=>{
		return api.put(`common/common/putdata/${dbname}`,data)
	},
	post:async (dbname,data)=>{
		return api.post(`common/common/postdata/${dbname}`,data)
	},
	del:async (dbname,id)=>{
		return api.delete(`common/common/${dbname}/${id}`)
	},
}