<template>
	<Container title="管理员列表" :returnBtn="false" :addBtn="addAccount" :onConfirm="false">
		<AccountTableView />
	</Container>
</template>

<script>
import moment from 'moment'
import { pub } from '/common/event'
	export default {
		data: () => {
			return {
				form: {},
				rules: {},
			}
		},
		mounted: function() {
			
		},
		methods:{
			onConfirm: async function() {
				console.log(this.form)
				this.$refs['formName'].validate(async (valid) => {
					if (valid) {
						const ret = await this.$api.account.post(this.form)
						if(ret){
							pub("success_message","添加成功")
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			addAccount:function(){
				this.$router.push("/system/account/add")
			}
		}
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>