const funcs = {}

const sub = function(key, func) {
	if (funcs[key]) {
		funcs[key].push(func)
	} else {
		funcs[key] = [func]
	}
}

const sublike = function(key, func) {
	if (funcs[key+"__like"]) {
		funcs[key+"__like"].push(func)
	} else {
		funcs[key+"__like"] = [func]
	}
}

const pub = function(key, payload) {
	if (funcs[key]) {
		for (let f of funcs[key]) {
			f(payload)
		}
	}
}

const publike = function(key, payload) {
	const karr = key.split("_")
	const prekey = key.replace(karr[karr.length - 1],"_like")
	if (funcs[prekey]) {
		for (let f of funcs[prekey]) {
			f({payload,key})
		}
	}
}

const unsub = function(key, func) {
	if (!funcs[key]) return
	let temp = []
	for (let f of funcs[key]) {
		if (f == func) {
			continue
		} else {
			temp.push(f)
		}
	}
	funcs[key] = temp
}

const unsublike = function(key, func) {
	if (!funcs[key+"__like"]) return
	let temp = []
	for (let f of funcs[key+"__like"]) {
		if (f == func) {
			continue
		} else {
			temp.push(f)
		}
	}
	funcs[key+"__like"] = temp
}

module.exports = {
	sub,
	pub,
	unsub,
	sublike,
	unsublike,
	publike
}