<template>
	<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: space-between;">
		<div style="margin-left: 20px;cursor: pointer;display: flex;align-items: center;height: 100%;">
			<el-icon size="22">
				<Fold @click="setMenuCollapse(false)" v-if="$store.state.config.menu.leftCollapse" />
				<Expand @click="setMenuCollapse(true)" v-else />
			</el-icon>
			<div style="width: 15px;"></div>
			<div @click="setCurrent(item.current)" v-for="(item,k) in list"
				style="display: flex;height: 100%;width: 90px;align-items: center;justify-content: center;flex-direction: column;"
				:style="{color:(k==current) ? 'white' : '#222',background:(k==current) ? 'linear-gradient(to top,#2a7fff,#5cc6ff)' : '#fff'}">
				<el-icon size="20">
					<component :is="$store.state.icons.icons[item.icon]"></component>
				</el-icon>
				<span style="font-size: 14px;margin-top: 3px;">{{item.name}}</span>
			</div>
		</div>
		<div style="display: flex;margin-right: 15px;align-items: center;">
			<el-avatar size="small" :src="$store.state.user.user.avatar"></el-avatar>
			<div style="margin-right: 5px;font-size: 15px;color: #666666;margin-left: 5px;">{{$store.state.user.user.name}}</div>
			<el-icon @click="logout" style="cursor: pointer;" color="#ff3838" size="20"><SwitchButton /></el-icon>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import menus from '../../config/menu';
import { pub } from '/common/event';
	export default {
		name:'BgyxMenuTop',
		data: () => {
			return {
				rmdicon: 0
			}
		},
		computed: {
			...mapState({
				menuConfig: (state) => {
					return state.config.menu
				},
				current: (state) => {
					return state.menu.current
				},
				list: (state) => {
					return state.menu.list.map((e, k) => {
						return {
							...e,
							current: k,
						}
					})
				},
			})
		},
		mounted() {
			this.rmdicon = Math.floor(Math.random() * 50)
		},
		methods: {
			logout:function(){
				localStorage.clear()
				pub("gologinpage",{})
			},
			makerdm: function(e) {
				return  Math.floor(Math.random() * 50)
			},
			setCurrent: function(e) {
				this.$store.commit(`menu/setCurrentData`, e)
			},
			setMenuCollapse: function(e) {
				this.$store.commit(`config/setMenuData`, {
					key: 'leftCollapse',
					data: e
				})

				this.$store.commit(`config/setMenuData`, {
					key: 'leftWidth',
					data: e ? this.$store.state.config.menu.topHeight : this.$store.state.config.origin
						.leftWidth
				})

			}
		},
	}
</script>

<style>
	.a {
		background: linear-gradient(to top, #2a7fff, #55aaff);
	}
</style>