<template>
	<div style="width: 100%;height: 100%;background: #ecf0fa;display: flex;justify-content: flex-end;align-items: center;box-shadow: 1px 1px 10px #d3d3d3;flex-shrink: 0;">
		<div
			style="width: calc(100% - 6px);height: calc(100% - 12px);background: white;border-radius: 2px;display: flex;flex-direction: column;flex-shrink: 0;">
			<div style="height: 50px; display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #ecf0fa;flex-shrink: 0;">
				<div style="display: flex;align-items: center;">
					<div style="width: 3px;height: 20px;background: #0055ff;border-radius: 10px;"></div>
					<div style="margin-left: 10px;font-weight: bold;">
						{{title}}
					</div>
				</div>
				<div style="margin-right: 10px;">
					<el-button v-if="addBtn && hideBtn" @click="addBtn" style="margin-left: 15px;" plain type="primary" icon="Plus">添加</el-button>
					<el-button v-if="returnBtn" @click="goBack" style="margin-left: 15px;" type="plain" icon="ArrowLeftBold">返回</el-button>
				</div>
			</div>
			<div style="flex: 1;width: 100%;background: white;overflow-y: auto;display: flex;flex-direction: column;flex-shrink: 0;">
				<div style="display: flex;flex-direction: column;overflow-y: auto;padding: 10px;flex: 1;background: #ecf0fa22;flex-shrink: 0;">
					<slot></slot>
				</div>
				<div style="width: 100%;height: 50px;display: flex;align-items: center;border-top: 1px solid #ecf0fa;" v-if="onConfirm || onCancel">
					<el-button @click="onConfirm" style="margin-left: 15px;" type="primary" icon="Promotion">确定</el-button>
					<el-button @click="onCancel" style="margin-left: 15px;" type="info" plain icon="Close">取消</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	    data:()=>{
	        return {
	            
	        }
	    },
	    props:{
	        title:{
	            type:String
	        },
			returnBtn:{
			    type:Boolean,
				default:false
			},
			addBtn:{
			    type:Function,
			},
			hideBtn:{
				type:Boolean,
				default:true
			},
			onConfirm:{
				type:Function,
			},
			onCancel:{
				type:Function,
			},
	    },
	    mounted() {
	        console.log(this.$route.params)
			
	    },
		methods:{
			goBack:function(){
				this.$router.back()
			},
		}
	}
</script>

<style>
</style>