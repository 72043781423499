<template>
	<Container title="BAIGUIYEX" :returnBtn="false" :addBtn="true" :onConfirm="onConfirm">
		<div style="width: 100%;display: flex;">
			<el-form :rules="rules" :model="form" label-width="auto" label-position="top" ref="formName"
				style="display: flex;width: 100%;flex-wrap: wrap;">
				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.name" />
				</el-form-item>
				<el-form-item required label="Activity name" style="width: 24%;margin: 0.5%;" prop="name1">
					<el-input v-model="form.name1" />
				</el-form-item>
				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input type="number" v-model="form.name2" />
				</el-form-item>
				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.name3" />
				</el-form-item>
				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.name4" />
				</el-form-item>
				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.name5" />
				</el-form-item>
				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.name6" />
				</el-form-item>

				<el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-date-picker v-model="form.value1" type="date" placeholder="选择日期" style="width: 100%;">
					</el-date-picker>
				</el-form-item>
				
				<el-form-item label="Activity name" style="width: 100%;margin: 0.5%;">
					<el-upload
					  class="avatar-uploader"
					  action="https://dx21.haoleen.com/uploader/upload"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess"
					  :before-upload="beforeAvatarUpload">
					  <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
					  <i v-else class="el-icon-plus avatar-uploader-icon">
						  <el-icon><UploadFilled /></el-icon>
					  </i>
					</el-upload>
				</el-form-item>

				<el-form-item label="Activity name" style="width: 100%;margin: 0.5%;">
					<FormEditItem value="<p>bgyx</p>" :form="form" name="content" />
				</el-form-item>


				<!-- <el-form-item label="Activity name" style="width: 24%;margin: 0.5%;">
					<el-input v-model="form.name7" />
				</el-form-item>
				<el-form-item label="Activity zone" style="width: 24%;margin: 0.5%;">
					<el-select v-model="form.region" placeholder="please select your zone" multiple filterable>
						<el-option label="Zone one" value="shanghai" />
						<el-option label="Zone two" value="beijing" />
					</el-select>
				</el-form-item>

				<el-form-item label="Activity form" style="width: 100%;margin: 0.5%;">
					<el-input v-model="form.desc" type="textarea" />
				</el-form-item>

				<el-form-item label="Activity form" style="width: 100%;margin: 0.5%;">
					<el-button type="submit">submit</el-button>
				</el-form-item> -->
			</el-form>
		</div>
	</Container>
</template>

<script>
import moment from 'moment'
	export default {
		data: () => {
			return {
				form: {
					region: ['shanghai']
				},
				rules: {
					name1: [{
						required: true,
						message: "aaaaa",
						trigger: 'blur'
					}]
				},
				errorcss: {
					name1: 1
				},
				aaa: 1
			}
		},
		mounted: function() {
			this.aaa = 8
		},
		methods: {
			onConfirm: async function() {
				// const ret = await this.$r.get('https://daiban.haoleen.com:2499/common/common/getdata/systeminfo/1')
				// const ret = await this.$r.post('http://dx7.haoleen.com:3405/dict/dict/all_list',{a:222})
				console.log( moment(this.form.value1).format('YYYY-MM-DD HH:mm:ss') )
				this.aaa = 0
				this.$refs['formName'].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			onSubmit: function() {
				alert(41)
			},
			beforeAvatarUpload:function(){},
			handleAvatarSuccess:function(e){
				console.log(e)
				this.form.imageUrl = e
			},
		},
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>