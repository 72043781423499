import request from "../../../common/request/index.js";
const api = request.api;

export default {
  post: async (data) => {
    return api.post(`dict/dict`, data);
  },
  put: async (data) => {
    return api.put(`dict/dict`, data);
  },
  putdata: async (data) => {
    return api.put(`dict/dict/dictdata`, data);
  },
  list: async () => {
    return api.get(`dict/dict/all_list`);
  },
  del: async (id) => {
    return api.delete(`dict/dict/${id}`);
  },
  deldata: async (id) => {
    return api.delete(`dict/dict/deldata/${id}`);
  },
  get: async (id) => {
    return api.get(`dict/dict/${id}`);
  },
  getDictData: async (id) => {
    return api.get(`dict/dict/getDictData/${id}`);
  },
  getDictDataByName: async (id) => {
    return api.get(`dict/dict/getDictDataByName/${id}`);
  },
  addDictData: async (data) => {
    return api.post(`dict/dict/addDictData`,data);
  },
  getDictAndData: async (id)=>{
    return api.get(`dict/dict/dictanddata/${id}`)
  }
};
