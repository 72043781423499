<template>
  <div>
    SSE-{{name}}
  </div>
</template>

<script>
export default {
    data:()=>{
        return {
            name:"Sse"
        }
    }
}
</script>