<template>
	<Container title="添加管理员" :returnBtn="false" :addBtn="false" :onConfirm="onConfirm">
		<div style="width: 100%;display: flex;padding-bottom: 50px;">
			<el-form :rules="rules" :model="form" label-width="auto" label-position="top" ref="formName"
				style="display: flex;width: 100%;flex-direction: column;">
				<el-form-item label="头像" style="width: 100%;margin: 0.5%;">
					<el-upload
					  class="avatar-uploader"
					  :action="`${$store.state.config.host}:${$store.state.config.port}/upload/upload/image`"
					  :show-file-list="false"
					  :on-success="(e)=>{
						  this.form.avatar = e.data
					  }" >
					  <img v-if="form.avatar" :src="$store.state.common.systemInfo.fileurl + form.avatar" class="avatar">
					  <i v-else class="el-icon-plus avatar-uploader-icon">
						  <el-icon><UploadFilled /></el-icon>
					  </i>
					</el-upload>
				</el-form-item>
				<el-form-item required label="管理员账号" style="width: 24%;margin: 0.5%;" prop="account">
					<el-input v-model="form.account" placeholder="请填写管理员账号" />
				</el-form-item>
				<el-form-item required label="管理员名称" style="width: 24%;margin: 0.5%;" prop="name">
					<el-input v-model="form.name" placeholder="请填写管理员名称" />
				</el-form-item>
				<el-form-item required label="登录密码" style="width: 24%;margin: 0.5%;" prop="password">
					<el-input v-model="form.password" placeholder="请填写登录密码" />
				</el-form-item>
				
				<el-form-item required label="账号状态" style="width: 24%;margin: 0.5%;">
					<el-select v-model="form.status" placeholder="请选择状态">
						<el-option label="正常" value="1" />
						<el-option label="停用" value="0" />
					</el-select>
				</el-form-item>

				<el-form-item required label="扩展字段" style="width: 24%;margin: 0.5%;" prop="customfield">
					<el-input v-model="form.customfield" placeholder="请填写扩展字段" />
				</el-form-item>

			</el-form>
		</div>
	</Container>
</template>

<script>
import moment from 'moment'
import { pub } from '/common/event'
	export default {
		data: () => {
			return {
				form: {},
				rules: {},
			}
		},
		mounted: function() {
			
		},
		methods: {
			onConfirm: async function() {
				console.log(this.form)
				this.$refs['formName'].validate(async (valid) => {
					if (valid) {
						const ret = await this.$api.account.post(this.form)
						if(ret){
							pub("success_message","添加成功")
							this.form = {}
							this.$refs['formName'].resetFields()
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		},
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>