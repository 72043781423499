<template>
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 1px;
      "
    >
      <div
        style="
          width: 100%;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 2px;
          flex: 1;
        "
      >
        <el-form
          :model="form"
          label-width="auto"
          label-position="top"
          ref="formName"
          style="display: flex; width: 100%; flex-wrap: wrap"
        >
          <el-form-item
            label="数据表索引"
            style="width: 24%; margin: 0.5%"
            prop="dbname"
          >
            <el-input clearable v-model="form.dbname" placeholder="搜索数据表索引" />
          </el-form-item>
          <el-form-item
            label="数据表名称"
            style="width: 24%; margin: 0.5%"
            prop="dbtitle"
          >
            <el-input clearable v-model="form.dbtitle" placeholder="搜索数据表名称" />
          </el-form-item>
          
        </el-form>
      </div>
      <div
        style="
          padding: 20px;
          display: flex;
          align-items: center;
          margin-top: 25px;
        "
      >
        <div>
          <el-button @click="reset">重置</el-button>
        </div>
        <div style="margin-left: 5px">
          <el-button @click="search" type="primary">查询</el-button>
        </div>
      </div>
    </div>
    <div
      style="width: 100%; flex: 1; flex-direction: column; overflow-y: hidden"
    >
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          background: blue;
          height: 92%;
        "
      >
        <el-table
          :data="tableData"
          height="100%"
          style="width: 100%"
          :border="true"
          stripe
          :header-cell-style="stt"
        >
          <el-table-column prop="dbname" label="数据表索引" />
          <el-table-column prop="dbtitle" label="数据表名称">
            <template #default="{ row }">
              {{ row.dbtitle}}
            </template>
          </el-table-column>
          <el-table-column prop="dbdesc" label="说明"></el-table-column>
          <el-table-column fixed="right" label="操作" width="160">
            <template #default="{ row }">
              <el-button
                type="primary"
                size="small"
                plain
                @click.stop="edit(row)"
              >
                字段
              </el-button>
              
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          height: 8%;
          justify-content: flex-end;
          align-items: center;
        "
      >
        <div style="margin-right: 10px">
          <el-pagination
            @change="pageChange"
            v-model:current-page="page.current"
            :page-size="page.pageSize"
            :total="page.total"
            background
            layout="prev, pager, next"
          />
        </div>
      </div>
    </div>

   </div>
</template>

<script>
import { pub } from '../../../common/event';
export default {
  data: () => {
    return {
      form: {},
      formEdit: {},
      tableData: [],
      page: {
        current: 1,
        pageSize: 15,
        total: 15,
      },
      dialogFormVisible: false,
      rules: {},
      stt:{
        "background":"#209cd111",
        "color":"#209cd1"
      }
    };
  },
  props: {},
  mounted() {
    this.getData();
  },
  methods: {
    filterWhere: function () {
      const whereform = this.form;
      for (let k in whereform) {
        if (!whereform[k]) {
          delete whereform[k];
        }
      }
      const where = {
        current: this.page.current,
        pageSize: this.page.pageSize,
        ...whereform,
      };

      return where;
    },
    getData: async function () {
      const where = this.filterWhere();
      const ret = await this.$api.common.page('dbinfo',where);
      console.log(ret);
      this.tableData = ret.data.data;
      this.page.total = ret.data.total;
      this.page.current = ret.data.page.current;
      this.page.pageSize = ret.data.page.pageSize;
    },
    edit: function (e) {
      this.$router.push("/system/dbfields?id="+e.id)
    },
    
    pageChange: function (e, v) {
      console.log(e, v);
      this.page.current = e;
      this.page.pageSize = v;
      this.getData();
    },
    reset: function () {
      this.form = {};
      this.$refs["formName"].resetFields();
      this.getData();
    },
    search: function () {
      this.getData();
    },
  },
};
</script>

<style></style>