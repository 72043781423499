import store from '../index.js'
import config from '../../common/config.js'
import request from '../../common/request/index'
import { sub } from '../../common/event.js'

export default {
	namespaced: true,
	state: {
		list: [],
	},
	mutations: {
		setList: (state, payload) => {
			state.list = payload
			store.commit(`menu/setBgyxMenus`, payload.filter(e => e.status == 2).map((e, k) => {
				return {
					name: e.dbtitle,
					index: k,
					icon: e.icon,
					menus: [{
						name: e.dbtitle + "列表",
						path: "/bgyx/database/list?dbname=" + e.dbname.replace(`bgyx_`,''),
					}, {
						name: "添加" + e.dbtitle,
						path: "/bgyx/database/add?dbname=" + e.dbname.replace(`bgyx_`,''),
					}]
				}
			}))
		},
	},
	actions: {
		async getList({
			commit,
			state
		}, {
			payload
		}) {
			const ret = await request.api.get(`bgyx/bgyx/alldatabse`)
			if (ret && ret.success) {
				commit(`setList`, ret.data)
			}
		}
	},
	init: async function() {
		console.log("database初始化")
		const user = localStorage.getItem("user")
		if(user){
			store.dispatch({
				type: "database/getList",
				payload: {}
			})
		}

		sub("loginSuccess",(e)=>{
			store.dispatch({
				type: "database/getList",
				payload: {}
			})
		})
		
	}
}