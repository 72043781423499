<template>
  <div>
    ABOUT-{{name}}
  </div>
</template>

<script>
export default {
    data:()=>{
        return {
            name:"ABOUT"
        }
    },
    mounted() {
        console.log(this.$route.query)
    },
}
</script>