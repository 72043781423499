import data from './data.js'
import store from '../../store/index.js'

const getLang = (k)=>{
	const languageData = data.data
	if(languageData[k]){
		return languageData[k][store.state.config.language]
	}else{
		return languageData[k]
	}
}

export default {
  install(app) {
	  app.config.globalProperties.$lg = getLang
  },
  getLang
}