import { createWebHashHistory, createRouter } from "vue-router";

const init = function () {
  const modulesFiles = require.context("../pages", true, /\.vue$/);

  const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    const routerData = {
      name: moduleName,
      component: value.default,
    };
    let path =  "/" + moduleName.toLowerCase()
    if(moduleName.includes('/$')){
        const mnarr = moduleName.split('/')
        const lastna = mnarr[mnarr.length - 1]
        const paramsname = lastna.replace('$','')
        mnarr[mnarr.length - 1] = ":"+paramsname
        path = "/" + mnarr.join('/')
    }
    routerData.path = path
    modules[moduleName] = routerData;
    return modules;
  }, {});

  const routers = [];

  for (let k in modules) {
    routers.push(modules[k]);
  }

  const router = createRouter({
    history: createWebHashHistory(),
    routes: routers,
  });

  console.log(routers)

  return router
};

export default {
  init,
};
