
 
export default {
  install(app) {
	  const modulesFiles = require.context("../components", true, /\.vue$/);
	  
	  const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
	    const value = modulesFiles(modulePath);
	    const namearr = moduleName.split('/')
		const name = namearr[namearr.length - 1]
	    modules[name] = value.default;
	    return modules;
	  }, {});
	  
	  console.log("全局组件初始化",modules)
	  
	  for(let k in modules){
		  app.component(k, modules[k])
	  }
  }
}