<template>
	<div class="tcon">
		<div class="tcon1" v-if="dict.id">
			<div class="tcon2">
				<div style="margin-right: 5px">
					<el-button @click="adddictdata" type="primary">添加</el-button>
				</div>
			</div>
		</div>
		<div style="width: 100%; flex: 1; flex-direction: column; overflow-y: hidden" v-if="dict.id">
			<div class="tcon3">
				<el-table :data="tableData" height="100%" style="width: 100%" :border="true" stripe
					:header-cell-style="stt">
					<el-table-column prop="id" label="值"></el-table-column>
					<el-table-column prop="name" label="标签">
						<template #default="{ row }">
							{{ row.name }}
						</template>
					</el-table-column>
					<el-table-column v-if="dict.ishavenote == 1" prop="note" label="说明"></el-table-column>
					<el-table-column v-if="dict.ishavepicture == 1" prop="picture" label="图像">
						<template #default="{ row }">
							<img v-if="row.picture" :src="$store.state.common.systemInfo.fileurl + row.picture" style="width: 30px;height: 30px;" alt="" />
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="160">
						<template #default="{ row }">
							<el-button type="primary" size="small" plain @click.stop="edit(row)">
								编辑
							</el-button>
							<el-popconfirm confirm-button-text="删除" cancel-button-text="取消" title="确定删除吗?"
								@confirm="del(row)">
								<template #reference>
									<el-button type="danger" size="small" plain>删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<div style="width: 100%; flex: 1; flex-direction: column; overflow-y: hidden;height: 100%;" v-else>
			<el-empty description="no data" />
		</div>

		<el-dialog v-model="showadd" :title="`添加字典${dict.name}数据`" width="30%">
			<el-form :rules="rules" :model="formAdd" label-width="auto" label-position="top" ref="formName"
				style="display: flex; width: 100%; flex-direction: column;">
				<el-form-item v-if="dict.ishavepicture" label="图像" style="width: 100%; margin: 15px;">
					<el-upload class="avatar-uploader"
						:action="`${$store.state.config.host}:${$store.state.config.port}/upload/upload/image`"
						:show-file-list="false" :on-success="(e) => {
		        this.formAdd.picture = e.data;
		      }
		    ">
						<img v-if="formAdd.picture" :src="$store.state.common.systemInfo.fileurl + formAdd.picture" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon">
							<el-icon>
								<UploadFilled />
							</el-icon>
						</i>
					</el-upload>
				</el-form-item>
				<el-form-item required label="数据标签" style="width: 100%; margin: 15px;" prop="name">
					<el-input v-model="formAdd.name" placeholder="请填写数据标签" />
				</el-form-item>
				<el-form-item v-if="dict.ishavenote" required label="数据说明" style="width: 100%; margin: 15px;" prop="note">
					<el-input v-model="formAdd.note" placeholder="请填写数据说明" />
				</el-form-item>
				
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="showadd = false">取消</el-button>
					<el-button type="primary" @click="confirmAdd">
						确定
					</el-button>
				</div>
			</template>
		</el-dialog>

		<el-dialog v-model="dialogFormVisible" title="编辑字典项数据" width="30%">
			<el-form :rules="rules" :model="formEdit" label-width="auto" label-position="top" ref="formName"
				style="display: flex; width: 100%; flex-direction: column;">
				<el-form-item v-if="dict.ishavepicture" label="图像" style="width: 100%; margin: 15px;">
					<el-upload class="avatar-uploader"
						:action="`${$store.state.config.host}:${$store.state.config.port}/upload/upload/image`"
						:show-file-list="false" :on-success="(e) => {
			    this.formEdit.picture = e.data;
			  }
			">
						<img v-if="formEdit.picture" :src="$store.state.common.systemInfo.fileurl + formEdit.picture" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon">
							<el-icon>
								<UploadFilled />
							</el-icon>
						</i>
					</el-upload>
				</el-form-item>
				<el-form-item required label="数据标签" style="width: 100%; margin: 15px" prop="name">
					<el-input v-model="formEdit.name" placeholder="请填写数据标签" />
				</el-form-item>
				<el-form-item v-if="dict.ishavenote" required label="数据说明" style="width: 100%; margin:15px;" prop="note">
					<el-input v-model="formEdit.note" placeholder="请填写数据说明" />
				</el-form-item>
				
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取消</el-button>
					<el-button type="primary" @click="confirmEdit">
						确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import { tSMethodSignature } from '@babel/types';
import {
		pub
	} from '../../../common/event';
	import {
		mapState
	} from "vuex";
	export default {
		data: () => {
			return {
				form: {},
				formEdit: {},
				formAdd: {},
				tableData: [],
				dialogFormVisible: false,
				rules: {},
				stt: {
					"background": "#209cd111",
					"color": "#209cd1"
				},
				showadd: false
			};
		},
		mounted() {
			if(this.$store.state.dict.current.id){
				this.getData(this.$store.state.dict.current);
			}
		},
		watch: {
			dict: function(v) {
				this.getData(v);
			}
		},
		computed: {
			...mapState({
				dict: (state) => {
					return state.dict.current;
				},
			}),
		},
		methods: {
			filterWhere: function() {
				const whereform = this.form;
				for (let k in whereform) {
					if (!whereform[k]) {
						delete whereform[k];
					}
				}
				const where = {
					current: this.page.current,
					pageSize: this.page.pageSize,
					...whereform,
				};

				return where;
			},
			getData: async function(item) {
				console.log(item);
				if (item.id) {
					const ret = await this.$api.dict.getDictData(item.id)
					if(ret.success){
						this.tableData = ret.data
					}
				}
			},
			confirmAdd: async function(e) {
				console.log(this.formAdd);
				this.formAdd.dict_id = this.dict.id
				const ret = await this.$api.dict.addDictData(this.formAdd)
				this.showadd = false
				pub("success_message", "添加成功")
				this.getData(this.dict);
				this.formAdd = {}
				this.$store.dispatch({
				    type:"dict/getDictDatas",
				    payload:{}
				})
			},
			confirmEdit: async function(e) {
				console.log(this.formEdit);
				const ret = await this.$api.dict.putdata(this.formEdit)
				this.dialogFormVisible = false
				pub("success_message", "编辑成功")
				this.getData(this.dict);
				this.$store.dispatch({
				    type:"dict/getDictDatas",
				    payload:{}
				})
			},
			edit: function(e) {
				console.log(e);
				this.formEdit = JSON.parse(JSON.stringify(e))
				this.dialogFormVisible = true;
			},
			del: async function(e) {
				console.log(e);
				const ret = await this.$api.dict.deldata(e.id);
				pub("success_message", "删除成功")
				this.getData(this.dict);
				this.$store.dispatch({
				    type:"dict/getDictDatas",
				    payload:{}
				})
			},
			pageChange: function(e, v) {
				console.log(e, v);
				this.page.current = e;
				this.page.pageSize = v;
				this.getData();
			},
			reset: function() {
				this.form = {};
				this.$refs["formName"].resetFields();
				this.getData();
			},
			adddictdata: function() {
				this.showadd = true
			},
		},
	};
</script>

<style scoped>
	.tcon {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		overflow: hidden;
	}

	.tcon1 {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 1px;
	}

	.tcon2 {
		padding: 12px;
		display: flex;
		align-items: center;
		margin-top: 5px;
		justify-content: flex-end;
		width: 100%;

	}

	.tcon3 {
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		background: blue;
		height: 100%;

	}
</style>