<template>
	<el-sub-menu :index="k" v-if="menu.menus && menu.menus.length > 0">
		<template #title>
			<el-icon>
				<component :is="$store.state.icons.icons[menu.icon]"></component>
			</el-icon>
			<span>{{menu.name}}</span>
		</template>
		<BgyxMenuItem v-for="(item,ki) in menu.menus" :menu="item" :k="k+'_'+ki" :key="ki" />
	</el-sub-menu>
	<el-menu-item @click="gopage(menu.path)" :index="k" v-else >
		<el-icon v-if="menu.icon">
			<component :is="$store.state.icons.icons[menu.icon]"></component>
		</el-icon>
		<span v-if="firstLevelMenu()" style="margin-left: 10px;">{{menu.name}}</span>
		<span v-else>{{menu.name}}</span>
	</el-menu-item>
</template>

<script>
import { pub } from '../../../common/event'

	export default {
		name:"BgyxMenuItem",
		data:()=>{
			return {
				
			}
		},
		props:{
			menu:{
				type:Object
			},
			k:{
				type:[String,Number],
				default:1
			}
		},
		mounted() {
			
		},
		methods:{
			gopage:function(path){
				if(path){
					this.$router.push(path)
					if(path.includes(`bgyx/database/list`)){
						pub('bgyxdatabaselist',path)
					}
					if(path.includes(`bgyx/database/add`)){
						pub('bgyxdatabaseadd',path)
					}
				}
			},
			firstLevelMenu:function(){
				const pt = this.k + ""
				if(pt.includes("_")){
					return true
				}else{
					return false
				}
			},
		}
	}
</script>

<style>
</style>