import store from '../index.js'
import config from '../../common/config.js'
import request from '../../common/request/index'
import { pub } from '../../common/event.js'

export default {
	namespaced: true,
	state: {
		dicts:[],
		dictdatas:[],
		dictdatasmap:{},
        current:{}
	},
	mutations: {
		setDicts: (state, payload) => {
			state.dicts = payload
			pub(`dict_change`,{})
		},
		setDictDatas: (state, payload) => {
			state.dictdatas = payload
		},
		setCurrent: (state, payload) => {
			state.current = payload
		},
	},
	actions: {
		async getDicts({
			commit,
			state
		}, {
			payload
		}) {
		    const ret = await request.api.get(`dict/dict/all_list`)
		    if(ret && ret.success){
		        commit(`setDicts`,ret.data)
		    }
		},
		async getDictDatas({
			commit,
			state
		}, {
			payload
		}) {
		    const ret = await request.api.get(`dict/dict/all_datalist`)
		    if(ret && ret.success){
		        commit(`setDictDatas`,ret.data)
		    }
		}
	},
	init: async function() {
		console.log("dict初始化")
        store.dispatch({
            type:"dict/getDicts",
            payload:{}
        })
		
		store.dispatch({
		    type:"dict/getDictDatas",
		    payload:{}
		})
	}
}