<template>
	<div style="width: 100%;">
		<el-select v-model="form[name]" :placeholder="`填写${fieldname}`">
			<el-option v-for="(dictitem,dk) in list" :key="dk" :label="dictitem.name" :value="dictitem.id" />
		</el-select>
	</div>
</template>

<script>
	import {
		useStore
	} from 'vuex'

	export default {
		data() {
			return {
				list: [],
			}
		},
		props: {
			form: {
				type: Object
			},
			name: String,
			dbname: String,
			fieldname: String,
		},
		mounted() {
			this.init()
		},
		methods: {
			init: async function() {
				const ret = await this.$api.bgyx.getdata(this.dbname,{})
				this.list = ret.data
			}
		}
	}
</script>