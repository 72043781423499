import request from '../../../common/request/index.js'
const api = request.api

export default {
	makenewmodel:async (dbinfo,fields)=>{
		return api.post(`bgyx/bgyx/makenewmodel`,{dbinfo,fields})
	},
	editnewmodel:async (dbinfo,fields)=>{
		return api.put(`bgyx/bgyx/editnewmodel`,{dbinfo,fields})
	},
	getDbinfo:async (dbname)=>{
		return api.get(`bgyx/bgyx/getmodelinfo/${dbname}`)
	},
	get:async (dbname,id)=>{
		return api.get(`bgyx/bgyx/getdata/${dbname}/${id}`)
	},
	getdata:async (dbname,data)=>{
		return api.post(`bgyx/bgyx/getdata/${dbname}`,data)
	},
	getDbinfoByName:async (dbname)=>{
		return api.get(`bgyx/bgyx/getDbinfoByName/${dbname}`)
	},
	page:async (dbname,data)=>{
		return api.post(`bgyx/bgyx/page/${dbname}`,data)
	},
	bgyxpage:async (dbname,data)=>{
		return api.post(`bgyx/bgyx/bgyxpage/${dbname}`,data)
	},
	put:async (dbname,data)=>{
		return api.put(`bgyx/bgyx/putdata/${dbname}`,data)
	},
	post:async (dbname,data)=>{
		return api.post(`bgyx/bgyx/postdata/${dbname}`,data)
	},
	del:async (dbname,id)=>{
		return api.delete(`bgyx/bgyx/deldata/${dbname}/${id}`)
	},
	delmodel:async (id)=>{
		return api.delete(`bgyx/bgyx/delmodelinfo/${id}`)
	},
	restatusmodel:async (id)=>{
		return api.put(`bgyx/bgyx/restatusmodel/${id}`)
	},
}