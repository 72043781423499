<template>
	<div style="width: 100%;height: 100%;display: flex;flex-direction: column;">
		<div style="width: 100%;border-bottom: 1px solid rgb(236, 240, 250);background: white;display: flex;align-items: center;justify-content: center;" :style="{height:menuConfig.topHeight}">
			<img src="/static/logo.png" style="width: 35px;height: 35px;" alt="" />
			<div v-if="!$store.state.config.menu.leftCollapse" style="margin-left: 8px;font-weight: bold;">{{$store.state.common.systemInfo.name ? $store.state.common.systemInfo.name : '管理平台'}}</div>
		</div>
		<div style="width: 100%;flex: 1;overflow-y: auto;">
			<el-menu :collapse="$store.state.config.menu.leftCollapse" default-active="1" style="border-right: none;" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
				<BgyxMenuItem v-for="(item,k) in menus" :key="k" :menu="item" :k="k" />
			</el-menu>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import menus from '../../config/menu';
	export default {
		// name:'BgyxMenuLeft',
		data: () => {
			return {
				name: "lleefftt",
				itemces: [],
			}
		},
		computed: {
			...mapState({
				menuConfig: (state) => {
					return state.config.menu
				},
				menus:(state)=>{
					const ret = state.menu.list[state.menu.current].menus.filter(e=>{
						if(state.user.user.id == 1){
							return true
						}else{
							const authlist = state.user.auth
							if(authlist.includes(e.name)){
								return true
							}
							return false
						}
					})
					console.log("menumenu",ret,state.user)
					return ret
				},
			})
		},
		mounted() {
			
		},
		methods: {
			haveauth: function(e) {
				
			}
		},
	}
</script>