
export default {
	namespaced: true,
	state: {
		list:[],
		icons:{}
	},
	mutations: {
		setList:(state, payload)=>{
			state.list = payload
			for(let o of payload){
				state.icons[o.key] = o.icon
			}
		},
	},
	actions: {
		// async init({
		// 	commit,
		// 	state
		// }, {
		// 	payload
		// }) {
			
			
		// }
	},
	init: async function() {
		console.log("icons初始化")
	}
}