import { sub } from "../../common/event"
import store from "../index"
import req from "../../common/request/index.js"

export default {
	namespaced: true,
	state: {
		user:{},
		auth:[]
	},
	mutations: {
		setUserData:(state, payload)=>{
			state.user = payload
		},
		setUserAuth:(state, payload)=>{
			state.auth = payload
		},
		setUserInfo:(state, payload)=>{
			state.user[payload.key] = payload.value
		},
	},
	actions: {
		async getUserAuth({
			commit,
			state
		}, {
			payload
		}) {
			const ret = await req.api.get(`account/account/account_auth`)
			console.log("quanxian",ret) 
			commit(`setUserAuth`,ret.data)
		}
	},
	init: async function() {
		console.log("user初始化")
        const user = localStorage.getItem("user")
        console.log("user初始化",JSON.parse(user))
        if(user){
            store.commit(`user/setUserData`,JSON.parse(user))
            store.dispatch({
				type:`user/getUserAuth`,
				payload:{}
			})
        }
        sub("loginSuccess",(e)=>{
            store.commit(`user/setUserData`,e)
			store.dispatch({
				type:`user/getUserAuth`,
				payload:{}
			})
        })
	}
}