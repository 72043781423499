const { pub } = require("../event");
const configf = require("../config");

const axios = require("axios");

const router = require("../../src/init/routers.js");

const config_custom = {
  host: `${configf.host}:${configf.port}/`,
};

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    console.log(config.url);
    if (config.url.includes("/login/")) {
      return config;
    }
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      localStorage.clear();
      pub(`gologinpage`, {});
      return config;
    }
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    console.log("response", response);
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    const status = error.status;
    console.log("responseerror", error);
    if (status == 401) {
      
    } else {
      pub("request_error", error);
    }
    return status;
  }
);

function makeQueryStr(obj) {
  let str = "";
  for (let k in obj) {
    if (str) {
      str = str + "&" + k + "=" + obj[k];
    } else {
      str = str + "?" + k + "=" + obj[k];
    }
  }
  return str;
}

const bgyxPost = async (url, data) => {
  return new Promise(async (r) => {
    if (url.indexOf("http") != 0) {
      url = config_custom.host + url;
    }
    const ret = await axios.post(url, data);
    console.log("bgyxbgyx", ret);
    if (ret.data) {
      return r(ret);
    } else if (ret == 401) {
      // 尝试换token，成功后重新请求，失败清除token，跳转登录
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        axios
          .post(config_custom.host + "login/login/refreshTokenLogin", {
            token: refreshToken,
          })
          .then(async (res) => {
            console.log(res);
            if (res.success) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              // 重新发起请求
              const reret = await axios.post(url, data);
              if (reret.success) {
                r(reret);
              }
            } else {
              localStorage.clear();
              pub(`gologinpage`, {});
            }
          });
      } else {
        localStorage.clear();
        pub(`gologinpage`, {});
      }
    } else {
      return r(ret);
    }
  });
};

const bgyxGet = async (url, query) => {
  let querystr = "";
  if (query) {
    querystr = makeQueryStr(query);
  }
  return new Promise(async (r) => {
    if (url.indexOf("http") != 0) {
      url = config_custom.host + url;
    }
	const ret = await axios.get(url + querystr);
    console.log("bgyxbgyx", ret);
    if (ret.data) {
      return r(ret);
    } else if (ret == 401) {
      // 尝试换token，成功后重新请求，失败清除token，跳转登录
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        axios
          .post(config_custom.host + "login/login/refreshTokenLogin", {
            token: refreshToken,
          })
          .then(async (res) => {
            console.log(res);
            if (res.success) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              // 重新发起请求
              const reret = await axios.get(url + querystr);
              if (reret.success) {
                r(reret);
              }
            } else {
              localStorage.clear();
              pub(`gologinpage`, {});
            }
          });
      } else {
        localStorage.clear();
        pub(`gologinpage`, {});
      }
    } else {
      return r(ret);
    }
  });
};

const bgyxPut = async (url, data) => {
  return new Promise(async (r) => {
    if (url.indexOf("http") != 0) {
      url = config_custom.host + url;
    }
    const ret = await axios.put(url, data);
    console.log("bgyxbgyx", ret);
    if (ret.data) {
      return r(ret);
    } else if (ret == 401) {
      // 尝试换token，成功后重新请求，失败清除token，跳转登录
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        axios
          .post(config_custom.host + "login/login/refreshTokenLogin", {
            token: refreshToken,
          })
          .then(async (res) => {
            console.log(res);
            if (res.success) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              // 重新发起请求
              const reret = await axios.put(url, data);
              if (reret.success) {
                r(reret);
              }
            } else {
              localStorage.clear();
              pub(`gologinpage`, {});
            }
          });
      } else {
        localStorage.clear();
        pub(`gologinpage`, {});
      }
    } else {
      return r(ret);
    }
  });
};

const bgyxDelete = async (url, data) => {
  return new Promise(async (r) => {
    if (url.indexOf("http") != 0) {
      url = config_custom.host + url;
    }
    const ret = await axios.delete(url, data);
    console.log("bgyxbgyx", ret);
    if (ret.data) {
      return r(ret);
    } else if (ret == 401) {
      // 尝试换token，成功后重新请求，失败清除token，跳转登录
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        axios
          .post(config_custom.host + "login/login/refreshTokenLogin", {
            token: refreshToken,
          })
          .then(async (res) => {
            console.log(res);
            if (res.success) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              // 重新发起请求
              const reret = await axios.delete(url, data);
              if (reret.success) {
                r(reret);
              }
            } else {
              localStorage.clear();
              pub(`gologinpage`, {});
            }
          });
      } else {
        localStorage.clear();
        pub(`gologinpage`, {});
      }
    } else {
      return r(ret);
    }
  });
};

export default {
  install(app) {
    app.config.globalProperties.$r = {
      get: bgyxGet,
      post: bgyxPost,
      put: bgyxPut,
      delete: bgyxDelete,
    };
  },
  api: {
    get: bgyxGet,
    post: bgyxPost,
    put: bgyxPut,
    delete: bgyxDelete,
  },
};
