<template>
  <div style="width: 100%; display: flex; overflow: hidden; height: 100vh">
    <div
      style="
        display: flex;
        flex-direction: column;
        height: 100%;
        border-right: 1px solid rgb(236, 240, 250);
      "
      :style="{ width: $store.state.config.menu.leftWidth }"
      v-if="$store.state.config.leftbar"
    >
      <BgyxMenuLeft />
    </div>
    <div
      style="
        flex: 1;
        display: flex;
        flex-direction: column;
        background: rgb(236, 240, 250);
      "
    >
      <div
        style="display: flex; width: 100%; background: white"
        :style="{ height: $store.state.config.menu.topHeight }"
        v-if="$store.state.config.topbar"
      >
        <BgyxMenuTop />
      </div>
      <div style="flex: 1; width: 100%; overflow-y: hidden">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
window.ResizeObserver = class ResizeObserver extends window.ResizeObserver {
  constructor(callback) {
    let timer = null;
    const debouncedCallback = function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, 16);
    };
    super(debouncedCallback);
  }
};

import { sub } from "../common/event";
import socket from "../common/socket";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { initAllStore } from "../store/storeInits";
export default {
  name: "App",
  data: () => {
    return {};
  },
  methods: {
    tesss: function () {
      this.$bus.emit("aaa", 456789);
    },
  },
  mounted() {
    const route = useRoute();
    console.log(route.path);
    console.log(route.fullPath);

    if (route.path == "/") {
      // this.$router.replace({
      // 	path: "system/index",
      // 	params: {
      // 		username: "erina"
      // 	}
      // });
    }

    sub("request_error", (error) => {
      const status = error.status;
      ElMessage({
        message: error.message,
        type: "error",
        plain: true,
      });
    });

    sub("success_message", (msg) => {
      ElMessage({
        message: msg,
        type: "success",
        plain: true,
      });
    });

    sub("error_message", (msg) => {
      ElMessage({
        message: msg,
        type: "error",
        plain: true,
      });
    });

    sub("gologinpage", () => {
      this.$router.push(`/login/login`);
    });

    initAllStore();

    const user = localStorage.getItem("user");

    if (!user) {
      this.$router.push(`/login/login`);
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  padding: 0px;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 1px;
    /* 设置滚动条的宽度 */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0055ff99;
    /* 设置滚动条滑块的颜色 */
    border-radius: 5px;
    /* 设置滚动条滑块的圆角 */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* 设置滚动条滑块在悬停时的颜色 */
  }

  ::-webkit-scrollbar-track {
    background-color: #eee;
    /* 设置滚动条背景的颜色 */
  }
}

.content {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>