<template>
  <div>
    cansh-{{this.$route.params.id}}
  </div>
</template>

<script>
export default {
    data:()=>{
        return {
            name:"cccc"
        }
    },
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    mounted() {
        console.log(this.$route.params)
    },
}
</script>