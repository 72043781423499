import { createApp } from 'vue'
import App from './App.vue'

import mitt from "mitt"
import store from '../store/index'

import request from '../common/request/index.js'
import api from './service/index.js'
import language from './language/index.js'
import router from '../src/init/routers.js'
import components from '../src/init/components.js'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


const app = createApp(App)

// 编辑器
import VueQuillEditor from 'vue-quill-editor'
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
// 需要在new VUE之前
app.use(VueQuillEditor);
app.use(api);

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const icons = []
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  icons.push({
	  key,
	  icon:component
  })
  app.component(key, component)
}

store.commit(`icons/setList`,icons)

app.use(ElementPlus)

app.config.globalProperties.$bus = new mitt();
app.config.globalProperties.$store = store;
app.use(language)
app.use(request)
app.use(router.init())
app.use(components)
app.mount('#app')
