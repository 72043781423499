<template>
	<div style="width: 100%;display: flex;height: 100vh;">
		<div style="width:50%;height: 100%;background: linear-gradient(to right,rgb(29,142,228),rgb(29,112,216));border-end-end-radius: 100%;display: flex;flex-direction: column;justify-content: space-between;">
			<div style="margin-top: 50px;color: #e6f5ff;display: flex;flex-direction: column;">
				<div style="font-size: 30px;font-weight: bold;">
					{{$store.state.common.systemInfo.name}}
				</div>
				<div style="font-size: 14px;margin-top: 10px;color: #c1d9ff;">
					扩展式低代码数据管理平台
				</div>
			</div>
			<div>
				<img src="/static/loginbg.png" style="width: 60%;margin-bottom: 10px;" alt="" />
			</div>
		</div>
		<div style="width:50%;height: 100%;display: flex;align-items: center;justify-content: center;">
			<div style="width: 100%;display: flex;justify-content: center;margin-bottom: 50px;">
				<el-form :model="form" label-width="auto" label-position="top" ref="formName"
					style="display: flex;width: 40%;flex-direction: column;">
					<div style="font-size: 40px;font-weight: bold;margin-bottom: 15px;">
						账户登录
					</div>
					<el-form-item label="" style="width: 100%;margin: 0.5%;" prop="account">
						<el-input placeholder="输入账号" v-model="form.account" style="height: 50px;border-radius: 21px;border: 0px solid #e6f5ff;"  />
					</el-form-item>
					<el-form-item label="" style="width: 100%;margin: 0.5%;margin-top: 10px;" prop="password">
						<el-input placeholder="输入密码" v-model="form.password" style="height: 50px;border-radius: 21px;border: 0px solid #e6f5ff;" />
					</el-form-item>
					<el-form-item style="width: 100%;margin: 0.5%;margin-top: 20px;">
						<el-button round @click="login" style="width: 100%;height: 50px;border-radius: 50px;" type="primary">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import {
	pub
} from "/common/event";
import {
	mapState
} from "vuex";
export default {
	data: () => {
		return {
			form: {}
		};
	},
	beforeCreate: function () {
		this.$store.commit(`config/setLeftBar`, false)
		this.$store.commit(`config/setTopBar`, false)
	},
	mounted: function () {
		// this.$store.commit(`config/setLeftBar`,false)
		// this.$store.commit(`config/setTopBar`,false)
	},
	methods: {
		login: async function () {
			const ret = await this.$r.post(`login/login`, this.form)
			console.log(ret)
			if (ret && ret.success) {
				localStorage.setItem("user", JSON.stringify(ret.data.user))
				localStorage.setItem("token", ret.data.token)
				localStorage.setItem("refreshToken", ret.data.refreshToken)
				pub("loginSuccess", ret.data.user)
				this.$router.push("/system/index")
			}else{
				pub("error_message","用户名密码错误")
			}
		}
	},
};
</script>

<style scoped>
:deep .el-input {
  background: #9999922 !important;
  border: none !important;
  border-radius: 50px !important;

}

:deep .el-input__wrapper{
	background: #99999911;
	border: none !important;
	border-radius: 50px !important;
	padding-left: 20px;
}
</style>