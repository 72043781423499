<template>
	<Container title="数据库字段列表" :returnBtn="true" :addBtn="false" :onConfirm="false">
		<DbfieldsTableView v-if="id" :id="id" />
	</Container>
</template>

<script>
import moment from 'moment'
import { pub } from '/common/event'
import { useRoute } from 'vue-router';
	export default {
		data: () => {
			return {
				form: {},
				rules: {},
				id:''
			}
		},
		mounted: function() {
			const route = useRoute();
			console.log(route.query);
			this.id = route.query.id
		},
		methods:{
			
		}
	}
</script>

<style scoped>
	.errorcss {
		border: 1px solid #ff352e88;
		border-radius: 3px;
	}
</style>