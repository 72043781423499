<template>
	<Container :title="dbinfo.dbtitle" :returnBtn="false" :addBtn="addData" :onConfirm="false">
		<DbTableView v-if="dbname" :dbname="dbname" />
	</Container>
</template>

<script>
import moment from 'moment'
import { useRoute } from 'vue-router';
import { sub } from '../../../../common/event';
export default {
	data: () => {
		return {
			dbinfo: {},
			dbname: ''
		}
	},
	mounted: function () {
		const route = useRoute();
		console.log(route.query);
		this.dbname = route.query.dbname
		this.init(route.query.dbname)
		sub(`bgyxdatabaselist`, (e) => {
			console.log(996, e)
			this.getparams(e)
		})
	},
	methods: {
		getparams: function (e) {
			console.log(e);
			const esr = e.split(`dbname=`)
			const dbname = esr[1]
			this.dbname = dbname
			this.init(dbname)
		},
		init: async function (dbname) {
			const ret = await this.$api.bgyx.getDbinfoByName(dbname)
			console.log(ret)
			this.dbinfo = ret.data
		},
		addData: function () {
			this.$router.push("/bgyx/database/add?dbname=" + this.dbname)
		}
	}
}
</script>

<style scoped>
.errorcss {
	border: 1px solid #ff352e88;
	border-radius: 3px;
}
</style>